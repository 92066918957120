import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, pure } from "recompose";
import { IHeaderProps } from "../../types/Header";

function SandboxHeader(props: any): React.ReactElement {
  const {
    authUser,
    clientDoc
  }: {
    authUser: IHeaderProps["authUser"];
    clientDoc: {
      integrations: {
        webex: {
          conference: boolean;
        };
      };
    };
  } = props;
  const getColor = (): string => {
    let color = "#E7A150";
    if (
      authUser?.client?.includes("_gms1") ||
      !Number.isNaN(
        Number(authUser?.client?.substring(authUser.client.length - 1))
      )
    ) {
      color = "#32855F";
    } else if (authUser.client.includes("_preview")) {
      color = "#ABCC68";
    }

    return color;
  };
  const [showWebexMessage, setShowWebexMessage] = useState(false);
  const webexConference = clientDoc?.integrations?.webex?.conference;

  const currentDateRef = useRef(new Date());
  const webexMessageDate = new Date("2024-03-31");

  useEffect(() => {
    if (webexConference === true && currentDateRef.current < webexMessageDate) {
      setShowWebexMessage(true);
    }
  }, [webexConference]);

  const getLabel = (): string => {
    let label = "Sandbox";
    if (authUser.client?.includes("_gms1")) {
      label = "Implementation Preview";
    } else if (authUser.client.includes("_preview")) {
      label = "Sandbox Preview";
    } else if (
      !Number.isNaN(
        Number(authUser.client?.substring(authUser.client.length - 1))
      )
    ) {
      label = "Implementation";
    }
    return label;
  };
  return (
    <>
      {authUser && (
        <>
          <Box>
            {authUser?.client && import.meta.env.REACT_APP_ENV === "sandbox" ? (
              <div
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "white",
                  backgroundColor: getColor(),
                  width: "100%"
                }}
              >
                &nbsp;
                {getLabel()} - {authUser.client}
              </div>
            ) : (
              ""
            )}
          </Box>
        </>
      )}
    </>
  );
}
const mapStateToProps = (state: any) => ({
  authUser: state.sessionState.authUser,
  clientDoc: state.clientDocDataState.clientDocData
});

export default compose(
  pure,
  withRouter,
  connect(mapStateToProps)
)(SandboxHeader);
