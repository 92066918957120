import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { getReactAppUrl } from "../../constants";

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider?.providerId)
    .includes("password");

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return needsEmailVerification(this.props.authUser) ? (
        <div className="App">
          <header className="App-header">
            <div className="container">
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    padding: "20px",
                    margin: "10px 20px",
                    textAlign: "center",
                    border: "black solid 2px",
                    borderRadius: "0.5%"
                  }}
                >
                  <br />
                  <Alert variant="secondary">
                    <h5>
                      Email confirmation sent.
                      <br />
                      Please check your email (spam folder included) for a
                      confirmation email.
                      <br />
                      Refresh this page once you have confirmed your email.
                    </h5>
                  </Alert>
                  <div className="row">
                    <div className="col-12">
                      <Button
                        variant="primary"
                        onClick={this.onSendEmailVerification}
                        disabled={this.state.isSent}
                      >
                        <b>
                          <FontAwesomeIcon icon="mail-bulk" />
                          &nbsp;&nbsp;Resend Email
                        </b>
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        variant="primary"
                        onClick={() => {
                          document.location.href = getReactAppUrl();
                        }}
                      >
                        <b>
                          <FontAwesomeIcon icon="redo" />
                          &nbsp;&nbsp;Refresh Page
                        </b>
                      </Button>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </header>
        </div>
      ) : (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser
  });

  return compose(withFirebase, connect(mapStateToProps))(WithEmailVerification);
};

export default withEmailVerification;
